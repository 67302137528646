.list-section {
    padding-top: 150px;
    width: 100vw;
    padding-bottom: 100px;
}
  
.list-title-container {
    height: auto;
    width: 100vw;
    display: flex;
    justify-content: center;
}
  
.list-title {
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    color: rgb(178, 103, 103);
}
  
/* .solid-separator {
    margin: 0 50px 80px 50px;
    border-top: 2px solid rgb(127, 127, 127);
} */

@media screen and (max-width: 700px) {
    .list-title {
        font-size: 20px;
        padding: 8px;
    }
}

@media screen and (max-width: 480px) {
    .list-section {
        padding-top: 60px;
    }
}