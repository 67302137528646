.post-section {
    padding-top: 150px;
    width: 100%;
}

.post-content{
    margin: 0 100px;
}
  
.post-title-container {
    height: auto;
    width: 100vw;
    display: flex;
    justify-content: center;
}
  
.post-title {
    font-size: 30px;
    text-align: center;
    padding: 10px;
    font-size: 35px;
    font-weight: bold;
    color: #333;
}
.dark .post-title {
    color: rgb(204, 204, 204);
}
  
.solid-separator {
    margin: 0 50px 80px 50px;
    border-top: 2px solid rgb(127, 127, 127);
}

.post-content-text {
    padding-bottom: 100px;
    color: black;
}
.dark .post-content-text {
    color: white;
}

.post-content-text p {
    display: inline-flex;
    flex-wrap: wrap;
}
.post-content-text p a {
    color: #333;
}


@media screen and (max-width:700px) {
    .post-title {
        font-size: 20px;
    }
    .post-content {
        margin: 10vw;
        font-size: 20px;
    }
}

@media screen and (max-width:480px) {
    .post-section {
        padding-top: 60px;
    }
}